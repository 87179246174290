import { post, get, put, patch, remove } from "./common";
import config from "../config";
import { MyThunkAction } from "../types";
import { sendAlert } from "./UserAction";



export const ON_TICKETS_LOAD = "ON_TICKETS_LOAD";
export const ON_TICKETS_LOADING_START = "ON_TICKETS_LOADING_START";
export const ON_TICKETS_LOADING_FINISHED = "ON_TICKETS_LOADING_FINISHED";
export const ON_TICKET_LOADING_START = "ON_TICKET_LOADING_START";
export const ON_TICKET_LOADING_FINISHED = "ON_TICKET_LOADING_FINISHED";
export const ON_CREATE_TICKET = "ON_CREATE_TICKE";
export const GET_TICKET_BY_ID = "GET_TICKET_BY_ID";
export const UPDATE_TICKET_BY_ID ="UPDATE_TICKET_BY_ID";
export const ON_ADD_COMMENT = "ON_COMMENT_ADD";
export const ON_UPDATE_COMMENT = "ON_UPDATE_COMMENT_ADD";
export const ON_DELETE_COMMENT = "ON_DELETE_COMMENT_ADD";

export const getSupportTickets = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  dispatch({
    type: ON_TICKETS_LOADING_START,
    payload: true,
  });

  const response = await get(`/support/tickets`)

  dispatch({
    type: ON_TICKETS_LOAD,
    payload: response,
  });

  dispatch({
    type: ON_TICKETS_LOADING_FINISHED,
    payload: false,
  });

  return { status: 200 };
};

export const createSupportTicket = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  const response = await post(`/support/tickets`, queryString);
  const errorMsg = response?.error?.response?.data?.error
  
  if (response?.error) {
    dispatch(
      sendAlert({
        text: errorMsg || "An unexpected error occurred.",
        snack: true,
        snackTimeout: 6000,
        snackType: "error",
      }),
    );
    return { status: 400,};
  }
  else{
    dispatch(
      sendAlert({
        text: "Ticket Created Successfully",
        snack: true,
        snackTimeout: 6000,
        snackType: "success",
      }),
    )
  };
  dispatch({
    type: ON_CREATE_TICKET,
    payload: response,
  });
  dispatch(getSupportTickets())

  return { status: 200 };
};

export const getTicketById = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  dispatch({
    type: ON_TICKET_LOADING_START,
    payload: true,
  });

  const response = await get(`/support/tickets/${queryString}`)

  dispatch({
    type: GET_TICKET_BY_ID,
    payload: response,
  });

  dispatch({
    type: ON_TICKET_LOADING_FINISHED,
    payload: false,
  });

  return { status: 200 };
};

export const updateTicketById = (id?:string, queryString?:any): MyThunkAction => async (dispatch: any) => {

  const response = await patch(`/support/tickets/${id}`, queryString)
  const errorMsg = response?.error?.response?.data?.error
  if (response?.error) {
    dispatch(
      sendAlert({
        text: errorMsg ||"An unexpected error occurred.",
        snack: true,
        snackTimeout: 6000,
        snackType: "error",
      }),
    );
    return { status: 400,};
  }else{
    dispatch({
      type: UPDATE_TICKET_BY_ID,
      payload: {
        id: id,
        res:response
      },
    });
  }
  return { status: 200 };
};

export const addComment = (id?:string, comment?:any): MyThunkAction => async (dispatch: any) => {

  const response = await post(`/support/tickets/${id}/comments`, comment);
  const errorMsg = response?.error?.response?.data?.error

  if (response?.error) {
    dispatch(
      sendAlert({
        text: errorMsg|| "An unexpected error occurred.",
        snack: true,
        snackTimeout: 6000,
        snackType: "error",
      }),
    );
    return { status: 400,};
  }else{
    dispatch({
      type: ON_ADD_COMMENT,
      payload: {
        id: id,
        res:response
      },
    });
  }
  
  return { status: 200 };
};
export const updateComment = (id?:string, commentId?:string, comment?:any): MyThunkAction => async (dispatch: any) => {

  const response = await patch(`/support/tickets/${id}/comments/${commentId}`,comment);
  const errorMsg = response?.error?.response?.data?.error
  if (response?.error) {
    dispatch(
      sendAlert({
        text: errorMsg || "An unexpected error occurred.",
        snack: true,
        snackTimeout: 6000,
        snackType: "error",
      }),
    );
    return { status: 400,};
  }else{
    dispatch({
      type: ON_UPDATE_COMMENT,
      payload: {
        id: id,
        commentId: commentId,
        res:response
      },
    });
  }

  return { status: 200 };
};
export const deleteComment = (id?:string, commentId?:string): MyThunkAction => async (dispatch: any) => {

  const response = await remove(`/support/tickets/${id}/comments/${commentId}`,);
  if (response?.error) {
    const errorMsg = response?.error?.response?.data?.error
    dispatch(
      sendAlert({
        text:  errorMsg || "An unexpected error occurred.",
        snack: true,
        snackTimeout: 6000,
        snackType: "error",
      }),
    );
    return { status: 400,};
  }else{
    dispatch({
      type: ON_DELETE_COMMENT,
      payload: {
        id: id,
        commentId: commentId,
        res:response
      },
    });
  }

  return { status: 200 };
};





