export const SET_HEADER_INFO = "SET_HEADER_INFO";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

export const setHeaderInfo = (headerInfo: string) => ({
    type: SET_HEADER_INFO,
    payload: headerInfo,
  });
  
  export const setSearchText = (searchText: string) => ({
    type: SET_SEARCH_TEXT,
    payload: searchText,
  });