import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  ButtonGroup,
  colors,
  Divider,
  InputBase,
  Paper,
  Switch,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Settings from "../views/Settings";
import { stringToColor } from "../utils/utils";
import { Padding } from "@mui/icons-material";

export const drawerWidth = 240;

const isSetting = window.location.pathname.includes("settings");

export const ContainedButton = styled(Button)(({ theme }: { theme: any }) => ({
  borderRadius: 8,
  border: `0.5px solid ${theme.palette.primary.contrastText}`,
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: 14,
  fontWeight: 500,
  // lineHeight: "24px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover ": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `0.5px solid ${theme.palette.primary.contrastText}`,
  },
}));

export const DownLoadButton = styled(Button)(({ theme }: { theme: any }) => ({
  borderRadius: 8,
  border: `0.5px solid ${theme.palette.primary.contrastText}`,
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: 12,
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  // lineHeight: "24px",
  "&:hover ": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "&.Mui-disabled": {
    color: theme.palette.primary.contrastText,
    filter: "grayscale(0.5)",
  },
}));

export const SecondaryButton = styled(Button)(({ theme }: { theme: any }) => ({
  height: 36,
  borderRadius: 8,
  border: `0.8px solid ${theme.palette.text.primary}`,
  boxShadow: "unset",
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "24px",
  padding: "4px 16px",
  "&:hover ": {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: "unset",
    border: `0.8px solid ${theme.palette.text.primary}`,
  },
}));
export const TextButton = styled(Button)(({ theme }: { theme: any }) => ({
  color: theme.palette.text.primary,
  fontWeight: 800,
  minWidth: "unset",
  // padding:0,
  lineHeight: "8px",
  borderBottom: `1px solid transparent`,
  borderRadius: 0,
  "&:hover": {
    background: "unset",
    borderColor: theme.palette.text.primary,
  },
}));

export const ButtonWrap = styled(ButtonGroup)(
  ({ theme, active }: { theme?: any; active?: boolean }) => ({
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `unset`,
    boxShadow: "unset",
    borderRadius: 8,
    "&:hover": {
      // border:` 1px solid ${theme.palette.grey[300]}`,
      background: theme.palette.background.paper,
    },
    "& button": {
      border: ` 0.8px solid ${theme.palette.grey[50]}`,
      boxShadow: "unset",
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 500,
      height: 48,
      borderRadius: 8,
      "&:hover": {
        border: ` 0.8px solid ${theme.palette.grey[50]}`,
        background: theme.palette.background.paper,
        color: theme.palette.primary.contrastText,
      },

      "&:active": {
        color: theme.palette.primary.main,
      },
      "&:last-child": {
        color: theme.palette.text.primary,
        "&:hover": {
          border: ` 1px solid ${theme.palette.grey[300]}`,
          background: theme.palette.error.light,
          color: theme.palette.error.main,
        },
        "&:first-child": {
          color: theme.palette.primary.main,
          "&:hover": {
            border: ` 1px solid ${theme.palette.grey[300]}`,
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
        "&:active": {
          color: theme.palette.error.main,
        },
      },
    },
  })
);

export const PaperCard = styled(Paper)(({ theme }: { theme: any }) => ({
  background: theme.palette.background.paper,
  boxShadow: `4px 4px 8px -4px #3345401F`,
  borderRadius: 2,
  padding: 16,
  marginBottom: 16,
  border: `1px solid transparent`,
}));

export const Dividers = styled(Divider)(({ theme }: { theme: any }) => ({
  // color: theme.palette.grey[50], //"#D8DEEB",
  borderWidth: 0.2,
  borderColor: theme.palette.grey[50], //"#D8DEEB",
}));

export const TextInput = styled(TextField)(({ theme }: { theme: any }) => ({
  width: "100% !important",
  fontSize: 14,
  fontWeight: 600,
  fontFamily: "Manrope",
  marginTop: 8,
  marginLeft: "0px !important",
  "& input": {
    border: "unset",
    padding: 8,
    width: "100%",
    background: theme.palette.background.neutral,
  },
  "& fieldset": {
    // border: "unset",
    // border: `1px solid   rgba(255, 255, 255, 0.23)`, //${theme.palette.grey[300]}
    borderRadius: 3,
  },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: 16,
  height: "calc( 100vh - 295px)",
  background: theme.palette.background.paper,
  overflow: "auto",
  borderRadius: 8,
  // borderBottomRightRadius: 0,
  // borderBottomLeftRadius: 0,
  paddingBottom: 1,
  position: "relative",
  "& thead": {
    "& th": {
      textTransform: "uppercase",
      fontSize:13,
      letterSpacing: 0.5,
      fontWeight: 900,
      borderBottom: `0.5px solid ${theme.palette.grey[50]}`,
      "&:last-child": {
        textAlign: "center",
      },
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td": {
    border: 0,
  },
  "& td": {
    borderColor: theme.palette.grey[50],
    borderWidth: 0.5,
    padding: "8px 16px",
    fontSize: 14,
  },
}));

export const StyledAvatar = styled(Avatar)(
  ({ theme, name }: { theme?: any; name: string }) => ({
    fontSize: 14,
    fontWeight: 900,
    color: theme.palette.text.white,
    background: stringToColor(name),
  })
);

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // Change the arrow color based on the theme
    color: theme.palette.mode === "light" ? "black" : "white",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === "light" ? "black" : "white",
    color: theme.palette.mode === "light" ? "white" : "black",
    padding: 8,
  },
}));

export const DrawerStyledTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // Change the arrow color based on the theme
    color: theme.palette.mode === "light" ? "white" : "white",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === "light" ? "white" : "white",
    color: theme.palette.mode === "light" ? "black" : "black",
    padding: 8,
  },
}));

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: theme.palette.text.primary,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor:"transparent",
    boxSizing: 'border-box',
  },
}));

