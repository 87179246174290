import React from "react";

const ExportIcon = (props: any) => {
  const { fill, style } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={style} viewBox="0 0 18 18" fill="none">
      <path d="M15.375 7.875V3.375C15.375 2.54657 14.7034 1.875 13.875 1.875H8.6838C8.286 1.875 7.90448 2.03303 7.62316 2.31434L3.81434 6.12316C3.53303 6.40447 3.375 6.78599 3.375 7.18382V15.375C3.375 16.2034 4.04657 16.875 4.875 16.875H8.625" stroke="#1B2121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.375 7.125H8.625V1.875" stroke="#1B2121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.875 14.25L13.875 17.25L10.875 14.25" stroke="#1B2121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.874 16.5L13.8748 17.25V10.875" stroke="#1B2121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default ExportIcon;
