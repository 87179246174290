import { GET_AUDIT_LOADING, GET_AUDIT_LOG_LOADING, GET_AUDIT_LOG_SUCCESS, GET_AUDIT_sUCCESS } from "../actions";
import { auditLogDetails, staticData } from "../views/Integrations/auditLogs/columns";

interface StoreState {
  auditLogList: any;
  isLoading: boolean;
  auditLogDetail: any
}

const initialState: StoreState = {
    auditLogList: staticData || [],
    isLoading:false,
    auditLogDetail:auditLogDetails || []
};

export const auditlogListReducer = (
  state = initialState,
  action: any
): StoreState => {
  switch (action.type) {
    case GET_AUDIT_LOADING:
      return { ...state, isLoading: true };

    case GET_AUDIT_sUCCESS:
      return {
        ...state,
        auditLogList: action.payload,
        isLoading: false,
      };

      case GET_AUDIT_LOG_LOADING:
        return { ...state, isLoading: true };
  
      case GET_AUDIT_LOG_SUCCESS:
        return {
          ...state,
          auditLogDetail: action.payload,
          isLoading: false,
        };
    
    default:
      return state;
  }
};
