import { post, get, putWithHeader, getWithHeader, postWithHeader } from "./common";
// import config from "../config";
import { MyThunkAction } from "../types";
import { sendAlert } from "./UserAction";
import { History } from 'history'; 
import { APP_ROUTES } from "../utils/constants";

export const GET_INTEGRATION_STATUS_LOADING="GET_INTEGRATION_STATUS_LOADING"
export const GET_INTIGRATION_STATUS_SUCESS = "GET_INTIGRATION_STATUS";
export const INTEGRATION_DATA_ALERT = "GET_INTEGRATION_DATA ";
export const GET_INTEGRATION_DATA = "GET_INTEGRATION_DATA ";
export const SET_PAYLOAD_DATA = "SET_PAYLOAD_DATA";
export const GET_INTEGRATION_METADATA = "GET_INTEGRATION_METADATA";
export const GET_CONFIG_METADATA = "GET_CONFIG_METADATA";
export const GET_PARTNER_METADATA = "GET_PARTNER_METADATA";
export const SET_CURRENT_STEP= "SET_CURRENT_STEP";
export const ON_DATA_LOADING_START = "ON_DATA_LOADING_START";
export const ON_DATA_LOADING_FINISHED = "ON_DATA_LOADING_FINISHED";

export const setCurrentStep = (queryString?:any) => ({
  type:SET_CURRENT_STEP,
  payload: queryString
});

export const setIntegrationQueryData = (queryString?:any,forceReset?:boolean) => ({
  type:SET_PAYLOAD_DATA,
  payload: queryString
});

export const getIntegratedData = (queryString?:any,history?:History,forceReset?:boolean): MyThunkAction => async (dispatch: any) => { 

  const data = null; // Add query params if needed
  const headers = queryString;

  dispatch({ type: ON_DATA_LOADING_START, payload:true });

  const response = await getWithHeader(`/integrations/partner`,queryString);

  if(response.error){
    const errorMsg = response?.error?.response?.data?.error
    dispatch(
      sendAlert({
        text:errorMsg || "Network error, please try again",
        snack: true,
        snackTimeout: 4000,
        snackType: "error",
      }),
    );
    history?.push(APP_ROUTES.integrations)
    return { status: 400,};
  }
  else{
    dispatch({
      type: GET_INTEGRATION_DATA ,
      payload: {
        forceReset,
        queryString: queryString,
        response: response
      },
    });
    dispatch({ type: ON_DATA_LOADING_FINISHED, payload:false });
  
    return { status: 200 };
  }
 
};

export const getIntegrationdMetaData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  const response = await get(`/integrations/partner/metadata`)

  dispatch({
    type: GET_INTEGRATION_METADATA ,
    payload:  response
  });
  return { status: 200 };
};

export const getConfigMetaData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {  

    const response = await getWithHeader(`/integrations/config/metadata`,queryString)
      dispatch({
        type: GET_CONFIG_METADATA,
        payload: {
          forceReset,
          response: response
        },
    
      });
    return { status: 200 };
};

export const getMarketConfigMetaData = (partnerName?:string, providerName?:string,history?:History,forceReset?:boolean): MyThunkAction => async (dispatch: any) => { 
  
  const provider = providerName;
  const partner = partnerName;
  
  dispatch({
    type: GET_INTEGRATION_STATUS_LOADING,
  });

  const response = await get(`/integrations/config/metadata/${provider}/${partner}`)
 if(response?.error){
  if(history)
    history.push(APP_ROUTES.integrations)
    return {status:500}
 }else{
    dispatch({
      type: GET_PARTNER_METADATA,
      payload: {
        forceReset,
        response: response
      },
  
    });
  
  return { status: 200 };
  }
    
};

export const CreateNewMarketConfig = (data:any,headers:any): MyThunkAction => async (dispatch: any) => {
    const response= await postWithHeader(`/integrations/market/config`,data,{headers})
        if (response?.error) {
          const errorMsg = response?.error?.response?.data?.error
          dispatch(
            sendAlert({
              text: errorMsg || "An unexpected error occurred.",
              snack: true,
              snackTimeout: 6000,
              snackType: "error",
            }),
          );
          return { status: 400,};
        }

    dispatch(getIntegratedData())
    
    dispatch(
          sendAlert({
            text: "Partner Created successfully.",
            snack: true,
            snackTimeout: 4000,
            snackType: "success",
          }),
        );
  
    return { status: 200 };
          
    };
  
export const getIntegrationStatusSectionList = (): MyThunkAction => async (dispatch: any) => {

  dispatch({
    type: GET_INTEGRATION_STATUS_LOADING,
  });
   
    const response = await get(`/integrations/status`)
    
      dispatch({
        type: GET_INTIGRATION_STATUS_SUCESS,
        payload:  response
      });
      return { status: 200 };
    };
    
