import { styled} from '@mui/material/styles';
import { Box, ListItemButton } from '@mui/material';
import DrawerBg from "../../assets/images/bg1.png";

export const drawerWidth = 240;


export const DrawerWrap = styled(Box)(
    ({ theme}: { theme: any }) => ({
      "& .MuiDrawer-paper": { 
          boxSizing: "border-box", 
          width: drawerWidth, 
          background: theme.palette.background.paper, 
          p: 2, 
          pr:0, 
          border:"unset" 
        }
      }
    )
  );

  export const DrawerInnerWrap = styled(Box)(
    ({ theme}: { theme: any }) => ({
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      borderRadius: 2,  //16
      boxShadow:"1px 0px 4px 0px #3345401F",
      height: "100%", 
      background: theme.palette.background.drawer,
      backgroundImage: `url(${DrawerBg})`,
      backgroundPositionX: "center",
      backgroundPositionY: "85%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "95%",
      backgroundAttachment: "scroll",

      padding: 16,      
      }
    )
  );
  export const ListItemBtn = styled(ListItemButton)(
    ({ theme}: { theme: any }) => ({
      margin: "8px auto",
      padding: 8,
      borderRadius: 4,
      color:theme.palette.text.drawer,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main, 
        color: theme.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      "&:hover": {
          backgroundColor: "transparent",
        },
      "& span":{
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: .7,
      },
      "& svg":{
        width: 24,
        height: 24
      }
    })
  );

  export const ListItemBtnSm = styled(ListItemButton)(
    ({ theme}: { theme: any }) => ({
      padding: "2px 16px",
      "&.Mui-selected": {
        backgroundColor: theme.palette.background.paper, 
        // color: "#fff",
        "&:hover": {
          // backgroundColor: theme.palette.primary.main,
        },
      },
      "&:hover": {
          backgroundColor: "transparent",
        },
      "& span":{
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: .7,
      },
    })
  );