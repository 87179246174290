import { SET_HEADER_INFO, SET_SEARCH_TEXT } from "../actions/HeaderAction";

interface HeaderState {
    headerInfo: string;
    searchText: string;
  }
  
  const initialState: HeaderState = {
    headerInfo: "",
    searchText: "",
  };
  
  export const headerReducer = (state = initialState, action:any): HeaderState => {
    switch (action.type) {
      case SET_HEADER_INFO:
        return { ...state, headerInfo: action.payload };
      case SET_SEARCH_TEXT:
        return { ...state, searchText: action.payload };
      default:
        return state;
    }
  };