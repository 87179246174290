import axios from "axios";
import config from '../config';

const apiKey = config.API_KEY;
const baseURL = config.BASE_URL;

const idToken = localStorage.getItem('idToken')

// Function to log out the user
const logout = () => {
  // Clear session storage and other relevant states
  localStorage.removeItem("idToken");
  localStorage.removeItem("refreshToken"); // If you use refresh tokens
  localStorage.removeItem("accessToken")
  window.location.href = "/login"; // Redirect to login page
};


const instance = axios.create({
  baseURL: config.BASE_URL, // Replace with your API's base URL
  timeout: 60000,
});



export const getToken = async () => {
  let idToken = localStorage.getItem("idToken");
  // If token is not available, wait and retry
  if (!idToken) {
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 500ms
    idToken = localStorage.getItem("idToken");
  }
  return idToken;
};

instance.interceptors.request.use(
  async (config) => {
    const idToken = await getToken();
    if (!idToken) {
      return Promise.reject(new Error('Authorization token is missing. API call aborted.'));
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    config.headers["Authorization"] = `Bearer ${idToken}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('isloggedIn', false)
      logout();
    }
    return Promise.reject(error);
  }
);


export const post = (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then((result) => {
        // console.log(result, 'results')
        if (result.status === 200) {
          resolve(result.data);
        } else {
          console.log('inside the eldse block')
          reject(result.data);
          // resolve(false);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};

export const get = (url, data) => {
  return new Promise((resolve) => {
    instance
      .get(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          // reject(result.data);
          resolve(false);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};

export const getWithHeader = (url, headers = {}) => {
  return new Promise((resolve) => {
    instance
      .get(url, { headers }) // Pass headers directly
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};

export const putWithHeader = (url, data, header) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data, header)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};

export const put = (url, data) => {
  return new Promise((resolve) => {
    instance
      .put(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        // console.log(error.response);
        resolve({ error: error || 500 });
      });
  });
};
export const patch = (url, data) => {
  return new Promise((resolve) => {
    instance
      .patch(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          // reject(result.data);
          resolve(false);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};

export const remove = async (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        data,
      })
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const postWithHeader = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, headers)
      .then((result) => {
        if (result.status === 200 || result.status === 202) {
          resolve(result.data);
        } else {
          // reject(result.data);
          resolve(false);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};

export const patchWithHeader = (url, data, header) => {
  return new Promise((resolve, reject) => {
    instance
      .patch(url, data, header)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        resolve({ error: error || 500 });
      });
  });
};