import { RESET_STATE, SET_CURRENT_PROVIDER_NAME, SET_EXPANDED, SET_SELECTED_ROW, SET_SERVICE } from "../actions";



const initialState = {
  selectedRow: null,
  service: [],
  currentProviderName: "",
  expanded:false
};

const selectionReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case SET_SELECTED_ROW:
      return { ...state, selectedRow: action.payload };

    case SET_SERVICE:
      return { ...state, service: action.payload };

      case SET_EXPANDED:
        return { ...state, expanded: action.payload };

    case SET_CURRENT_PROVIDER_NAME:
      return { ...state, currentProviderName: action.payload };

      case RESET_STATE: 
      return { ...initialState };

    default:
      return state;
  }
};

export default selectionReducer;
