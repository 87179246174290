
export const SET_SELECTED_ROW = "SET_SELECTED_ROW";
export const SET_SERVICE = "SET_SERVICE";
export const SET_CURRENT_PROVIDER_NAME = "SET_CURRENT_PROVIDER_NAME";
export const RESET_STATE = "RESET_STATE";
export const SET_EXPANDED = "SET_EXPANDED";

export const setSelectedRow = (rowIndex:any) => ({
  type: SET_SELECTED_ROW,
  payload: rowIndex,
});

export const setService = (serviceList:any) => ({
  type: SET_SERVICE,
  payload: serviceList,
});

export const setCurrentProviderName = (providerName:any) => ({
  type: SET_CURRENT_PROVIDER_NAME,
  payload: providerName,
});

export const resetSelectionState = () => ({
    type: RESET_STATE,
  });

  export const setExpanded = (extend:string | false) => ({
    type: SET_EXPANDED,
    payload: extend,
  });
  