import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DrawerInnerWrap, ListItemBtn, ListItemBtnSm } from "./styles";
import KhumbuLogo from "../SvgIcons/KhumbuLogo";
import AnalyticsIcon from "../SvgIcons/AnalyticsIcon";
import LogsIcon from "../SvgIcons/LogsIcon";
import DashboardIcon from "../SvgIcons/DashboardIcon";
import SignOutIcon from "../SvgIcons/SignOutIcon";
import {
  ArrowSquareInIcon,
  InvoiceIcon,
  KhumbuLogoIcon,
  QuestionIcon,
  SettingIcon,
  StatusIcon,
} from "../SvgIcons";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES, TIMEZONE } from "../../utils/constants";
import { DarkMode, DarkModeOutlined, LightMode, LightModeOutlined, MoreHorizSharp } from "@mui/icons-material";
import { Avatar, FormControlLabel, Popover, Skeleton, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducer";
import AlertDialog from "../AlertDialog";
import { stringAvatar } from "../../utils/utils";
import {
  GET_USER_INFO,
  getLogsListing,
  getmetaData,
  getUserInfo,
  setAnalyticsQueryData,
  setQueryData,
  setViewMode,
  UpdateUserInfo,
} from "../../actions";
import Snack from "../Snack";
import useAlert from "../Snack/useAlert";
import { AntSwitch, DrawerStyledTooltip, MaterialUISwitch, StyledAvatar, StyledTooltip } from "../commonStyles";
import { isEmpty } from "lodash";
import { ColorModeContext } from "../../App";
import moment from "moment";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "visible",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "visible",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: theme.palette.background.default,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const filteredMenuItems = (userInfo: any, pathName: any, theme: any) => {
  if (!userInfo || !userInfo.access) return [];

  const accessMap = userInfo.access;
  const MenuItem = [
    {
      title: "Dashboard",
      icon: (
        <DashboardIcon
          fill={
            pathName.includes(APP_ROUTES.dashboard)
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.dashboard,
      subPath: APP_ROUTES.dashboard,
      accessKey: "DASHBOARD",
    },
    {
      title: "Status",
      icon: (
        <StatusIcon
          fill={
            pathName.includes(APP_ROUTES.status)
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.status,
      subPath: APP_ROUTES.status,
      accessKey: "STATUS",
    },
    {
      title: "Logs",
      icon: (
        <LogsIcon
          fill={
            pathName.includes(APP_ROUTES.logs)
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.logs,
      subPath: APP_ROUTES.logs,
      accessKey: "LOGS",
    },
    {
      title: "Analytics",
      icon: (
        <AnalyticsIcon
          fill={
            pathName.includes(APP_ROUTES.analytics)
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.analytics,
      subPath: APP_ROUTES.analytics,
      accessKey: "ANALYTICS",
    },
    {
      title: "Integration",
      icon: (
        <ArrowSquareInIcon
          fill={
            pathName.includes("integrations")
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.integrations,
      subPath: APP_ROUTES.integrations,
      accessKey: "INTEGRATION",
    },
    {
      title: "Billing",
      icon: (
        <InvoiceIcon
          fill={
            pathName.includes(APP_ROUTES.invoices)
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.invoices,
      subPath: APP_ROUTES.invoices,
      accessKey: "BILLING",
    },
    {
      title: "Support",
      icon: (
        <QuestionIcon
          fill={
            pathName.includes(APP_ROUTES.support)
              ? theme.palette.primary.contrastText
              : theme.palette.text.drawer
          }
        />
      ),
      pathname: APP_ROUTES.support,
      subPath: APP_ROUTES.support,
      accessKey: "SUPPORT",
    },
  ];
  // Filter menu items based on user access
  return MenuItem.filter(
    (item) =>
      accessMap[item.accessKey] === "READ" ||
      accessMap[item.accessKey] === "WRITE"
  );
};

const PrimaryDrawer = (props: any) => {
  const { expand, handleDrawerOpen } = props;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  // const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState<any>([]);
  const [showAlertModal, setShowAlertModal] = React.useState(false);
  const [usertype, setUsertype] = React.useState("");
  const colorMode = React.useContext(ColorModeContext);

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(!drawerOpen);
  // };
  const pathName = history.location.pathname;

  const { currentStep } = useSelector((state: RootState) => state.integrations);
  const { userLoading, userInfo, queryData, AnalyticsQueryData } = useSelector((state: RootState) => ({
    userLoading: state.userInfo.userLoading,
    userInfo: state.userInfo.userInfo,
    queryData: state.logs.queryData,
    AnalyticsQueryData:state.analytics.AnalyticsQueryData
  }));

  const startOfday = moment().clone().startOf('day').format("YYYY-MM-DDTHH:mm:ss");
  const endOfday = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const nowOfday = moment().format("YYYY-MM-DDTHH:mm:ss")

  React.useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  React.useEffect(() => {
    if (!isEmpty(userInfo)) {
      const userType = userInfo?.role?.includes("PROVIDER")
        ? "PROVIDER"
        : "KHUMBU";
      setUsertype(userType);
      dispatch(getmetaData({ userType: userType, timeZone: TIMEZONE }));
      dispatch(setViewMode(userInfo.theme));
      localStorage.setItem("mode", userInfo.theme);
      colorMode.toggleColorMode(userInfo.theme);
    }
  }, [colorMode, dispatch, userInfo]);

  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen);
    handleDrawerOpen();
  };

  const MenuItem2 = [
    {
      title: "Setting",
      icon: <SettingIcon fill={theme.palette.text.primary} />,
      pathname: APP_ROUTES.settings,
      subPath: APP_ROUTES.settings,
    },
    // {
    //   title: "Help",
    //   icon: <QuestionIcon fill={selectedIndex === 6 ? "#FFF" : theme.palette.text.primary} />,
    //   pathname: APP_ROUTES.help,
    //   subPath: APP_ROUTES.help
    // },
    {
      title: "Logout",
      icon: <SignOutIcon fill={theme.palette.text.primary} />,
      pathname: APP_ROUTES.login,
      subPath: APP_ROUTES.login,
    },
  ];

  const pathname = history.location.pathname;

  const handleMenuOptionClick = React.useCallback(
    (option: any, idx: number) => {
      if (option.title === "Logout") {
        localStorage.setItem("isloggedIn", "false");
        sessionStorage.clear();
        dispatch({
          type: GET_USER_INFO,
          payload: {},
        });
        dispatch({ type: "LOGIN" });
        history.push(option.pathname);
      }
      if (option.title === "Logs") {
        dispatch(setQueryData({...queryData,
          startTime:startOfday, 
          endTime:moment().format("YYYY-MM-DDTHH:mm:ss"),
          searchText: "",   
          providerName:null,
          partnerName:null,
          type:null, 
          subType:null ,
          status:null,
          marketName:null
        }))
        history.push(option.pathname);
      }
      if (option.title === "Analytics") {        
        dispatch(setAnalyticsQueryData({...AnalyticsQueryData,
          startTime:startOfday, 
          endTime:moment().format("YYYY-MM-DDTHH:mm:ss"),
          searchText: "",   
          providerName:null,
          partnerName:null,
          type:null, 
          subType:null ,
          status:null,
          marketName:null
        }))
        history.push(option.pathname);
      }
       else {
        history.push(option.pathname);
      }

      // setSelectedIndex(idx);
      // setSelectedValue(option.pathName)
      setAnchorEl(null);
    },
    [dispatch, history]
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setShowAlertModal(false);
  };

  const handleLeaveDialoge = () => {
    handleMenuOptionClick(selectedOption, selectedIndex);
    setShowAlertModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedMode, setSelectedMode] = React.useState(""); //localStorage.getItem('mode')
  const [checked, setChecked] = React.useState<'light'|'dark'>(userInfo?.theme);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    setSelectedMode(event.target.checked? 'light':'dark');
    setChecked(event.target.checked? 'light':'dark')
    localStorage.setItem('mode', event.target.value);
    colorMode.toggleColorMode(event.target.value);
    dispatch(setViewMode(event.target.value))
    dispatch(
      UpdateUserInfo({
        name: userInfo?.name,
        email: userInfo?.email,
        theme: selectedMode,
      })
    );
  }; 

  const drawer = (
    <DrawerInnerWrap>
      <Box>
        {drawerOpen ? (
          <KhumbuLogo fill={theme.palette.text.drawer} />
        ) : (
          <KhumbuLogoIcon />
        )}
        <Divider sx={{ mt: 1 }} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box height="calc(100vh - 150px)" overflow="auto">
          <List>
            {filteredMenuItems(userInfo, pathName, theme).map((t, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  if (
                    pathname.includes("integrations") &&
                    (currentStep === "PROVIDER_CONFIG" ||
                      currentStep === "PARTNER_CONFIG")
                  ) {
                    setShowAlertModal(true);
                    setSelectedOption(t);
                    setSelectedIndex(index);
                  } else {
                    handleMenuOptionClick(t, index);
                  }
                }}
                disableGutters
                // selected={pathName.includes(t.pathname)}
              >
                <ListItemBtn
                  selected={pathName.includes(t.pathname)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    // px: 2.5,
                  }}
                >
                  {drawerOpen ? (
                    <ListItemIcon
                      sx={{
                        minWidth: 3,
                        "& svg": {
                          color: pathname.includes(t.pathname)
                            ? theme.palette.primary.contrastText // Selected icon color
                            : theme.palette.text.secondary, // Default icon color
                        },
                      }}
                    >
                      {t.icon}
                    </ListItemIcon>
                  ) : (
                    <StyledTooltip title={t.title} arrow placement="right-start">
                      <ListItemIcon
                        sx={{
                          minWidth: 3,
                          "& svg": {
                            color: pathname.includes(t.pathname)
                              ? theme.palette.primary.contrastText // Selected icon color
                              : theme.palette.text.secondary, // Default icon color
                          },
                        }}
                      >
                        {t.icon}
                      </ListItemIcon>
                    </StyledTooltip>
                  )}

                  <ListItemText
                    sx={{ ml: drawerOpen ? 1 : 0 }}
                    primary={drawerOpen ? t.title : ""}
                  />
                </ListItemBtn>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            {userLoading ? (
              <Skeleton variant="rounded" width={40} height={40} />
            ) : (
              <StyledAvatar
                variant="rounded"
                {...stringAvatar(userInfo ? userInfo?.name : "U")}
                sx={{ mr: 1, fontSize: 15, width: 32, height: 32 }}
                name={userInfo ? userInfo?.name : "U"}
              />
            )}
            {drawerOpen &&
              (userLoading ? (
                <Skeleton
                  variant="rectangular"
                  width={100}
                  sx={{ fontSize: 20 }}
                />
              ) : (
                <Typography
                  sx={{ color: theme.palette.text.drawer, whiteSpace: "wrap" }}
                >
                  {userInfo.name}
                </Typography>
              ))}
          </Box>

          <IconButton
            onClick={handleClick}
            sx={{ mr: drawerOpen ? -2 : 0, ml: drawerOpen ? 0 : "-20px" }}
          >
            <MoreHorizSharp
              htmlColor={theme.palette.text.drawer}
              sx={{ transform: drawerOpen ? "rotate(0deg)" : "rotate(90deg)" }}
            />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <List sx={{"& li":{
              borderBottom: `0.5px solid ${theme.palette.grey[50]}`,
              "&:last-child":{
                border:"unset"
              }}}}
            >
              <ListItem>
              <Stack direction="row" spacing={1} alignItems="center">
                <LightModeOutlined/>
                <AntSwitch checked={checked === 'light'} inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} />
                <DarkModeOutlined/>
              </Stack>
              </ListItem>
              {MenuItem2.map((t, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => {
                    if (
                      pathname.includes("integrations") &&
                      currentStep === "PROVIDER_CONFIG"
                    ) {
                      setShowAlertModal(true);
                    } else {
                      handleMenuOptionClick(t, index);
                    }
                  }}
                >
                  <ListItemBtnSm selected={selectedIndex === index}>
                    <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                      {t.icon}
                    </ListItemIcon>
                    <ListItemText primary={t.title} />
                  </ListItemBtnSm>
                </ListItem>
              ))}
            </List>
          </Popover>
        </Box>
      </Box>
    </DrawerInnerWrap>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            // width: drawerWidth,
            background: theme.palette.background.default,
            p: 2,
            pr: 0,
            border: "unset",
            overflowY: "visible",
          },
        }}
      >
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            width: 30,
            height: 30,
            position: "absolute",
            top: 60,
            right: -12,
            zIndex: 9999,
            background: theme.palette.background.paper,
            boxShadow: "8px 16px 64px 0px #3345401F",
            "&:hover": {
              background: theme.palette.background.paper,
            },
          }}
        >
          {!drawerOpen ? (
            <ChevronRightIcon htmlColor={theme.palette.text.primary} />
          ) : (
            <ChevronLeftIcon htmlColor={theme.palette.text.primary} />
          )}
        </IconButton>
        {drawer}
      </Drawer>
      {showAlertModal && (
        <AlertDialog
          handleClose={handleCloseDialog}
          handleLeave={handleLeaveDialoge}
        />
      )}
    </>
  );
};

export default PrimaryDrawer;
