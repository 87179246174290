import { post } from "./common";
// import config from "../config";
import { MyThunkAction } from "../types";
import { sendAlert } from "./UserAction";



export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const SET_ANALYTICS_QUERY_DATA = "SET_ANALYTICS_QUERY_DATA";


export const setAnalyticsQueryData = (queryString?:any,forceReset?:boolean) => ({
  type:SET_ANALYTICS_QUERY_DATA,
  payload: queryString
});

export const getAnalyticsData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  const response = await post(`/metrics/analytics`,queryString)

  if(response.error){
     dispatch(
                sendAlert({
                  text: "Network error, please try again",
                  snack: true,
                  snackTimeout: 4000,
                  snackType: "error",
                }),
              );
        }
  else{
    dispatch({
      type: GET_ANALYTICS_DATA,
      payload: {
        forceReset,
        queryString: queryString,
        response: response
      },
  
    });
  }



  return { status: 200 };
};





