import { MyThunkAction } from "../types";
import { post } from "./common";
import { sendAlert } from "./UserAction";

export const GET_AUDIT_LOADING = "GET_AUDIT_LOADING";
export const GET_AUDIT_sUCCESS = "GET_AUDIT_sUCCESS";

export const GET_AUDIT_LOG_LOADING = "GET_AUDIT_LOG_LOADING";
export const GET_AUDIT_LOG_SUCCESS = "GET_AUDIT_LOG_SUCCESS";

export const getAuditLogList =
  (queryString:any): MyThunkAction =>
  async (dispatch: any) => {

    dispatch({ type: GET_AUDIT_LOADING });

  const response = await post(`/metrics/list`,queryString)

   if(response?.error){
    const errorMsg = response?.error?.response?.data?.error
    dispatch(
        sendAlert({
          text: errorMsg || "An unexpected error occurred.",
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        }),
      );
      return {status:400}
   }
   else{
    dispatch({ type: GET_AUDIT_sUCCESS, payload: response });
       
    return {status:200}
   }

  };

  export const getAuditLogDetails =
  (queryString:any): MyThunkAction =>
  async (dispatch: any) => {

    dispatch({ type: GET_AUDIT_LOG_LOADING });

  const response = await post(`/metrics/list`,queryString)

   if(response?.error){
    const errorMsg = response?.error?.response?.data?.error
    dispatch(
        sendAlert({
          text: errorMsg || "An unexpected error occurred.",
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        }),
      );
      return {status:400}
   }
   else{
    dispatch({ type: GET_AUDIT_LOG_SUCCESS, payload: response });
       
    return {status:200}
   }

  };
