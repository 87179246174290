import { get, post } from "./common";
// import config from "../config";
import { MyThunkAction } from "../types";
import { ON_PAGINATION_LIST_LOADING, ON_PAGINATION_LIST_RESPONSE } from "./PaginationActions";
import { sendAlert } from "./UserAction";



export const ON_LOGS_LIST_LOAD = "ON_LOGS_LIST_LOAD";
export const GET_API_DETAILS = "GET_API_DETAILS";
export const GET_MEAT_DATA = "GET_MEAT_DATA";
export const CLEAR_API_DETAILS ="CLEAR_API_DETAILS"
export const ON_LOGS_LOADING_START = "ON_LOGS_LOADING_START";
export const ON_LOGS_LOADING_FINISHED = "ON_LOGS_LOADING_FINISHED";
export const ON_LOADING_START = "ON_LOADING_START";
export const ON_LOADING_FINISHED = "ON_LOADING_FINISHED";
export const SET_QUERY_DATA = "SET_QUERY_DATA";


export const GET_MENU_LIST_LOADING = "GET_MENU_LIST_LOADING";
export const GET_MENU_LIST_SUCCESS = "GET_MENU_LIST_SUCCESS";


export const clearApiData = () => ({
  type: CLEAR_API_DETAILS,
});

export const setQueryData = (queryString?:any, forceReset?:boolean) => ({
  type:SET_QUERY_DATA,
  payload: queryString
});

export const getLogsListing = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {  

  // dispatch({ type: ON_LOGS_LOADING_START, payload:true });

  const response = await post(`/metrics/list`,queryString)

  if(response?.error){
    dispatch({ type: ON_LOGS_LOADING_START, payload:false });
    const errorMsg = response?.error?.response?.data?.error;
    dispatch( sendAlert({
     text: "Record count is more than 200000, please refine search criteria and try again.",
     snack: true,
     snackTimeout: 4000,
     snackType: "error",
   }))
    
  }
  else{
    dispatch({
      type: ON_LOGS_LIST_LOAD,
      payload: {
        forceReset,
        queryString: queryString,
        response: response
      },
  
    });
    dispatch({ type: ON_LOGS_LOADING_FINISHED, payload:false });
    return { logs:response, status: 200 };
  }

  
};

export const getApiDetails = (queryString?:any, forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  dispatch({ type: ON_LOADING_START, payload:true });

  const response = await post(`/metrics/action`,queryString)

  dispatch({
    forceReset,
    type: GET_API_DETAILS,
    payload: {forceReset,response:response},
  });
  dispatch({ type: ON_LOADING_FINISHED, payload:false });
  return { status: 200 };
};


export const getmetaData = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  const response = await post(`/metrics/metadata`,queryString)

  dispatch({
    type: GET_MEAT_DATA,
    payload: response,
  });
  return { status: 200 };
};


export const getMenuList = (path: string): MyThunkAction => async (dispatch: any) => {  
  dispatch({ type: `${GET_MENU_LIST_LOADING}` });

  const response = await get(`metrics/action/menu/download?path=${path}`);

  if (response?.error) {
    const errorMsg = response?.error?.response?.data?.error;
    sendAlert({
      text: errorMsg || "An unexpected error occurred.",
      snack: true,
      snackTimeout: 4000,
      snackType: "error",
    });
    return { status: 400 };
  }

  return response;
};

export const getLogsExport = (queryString: any): MyThunkAction => async (dispatch: any) => {  

  const response = await post(`/metrics/list/export`,queryString)
  
  if (response?.error) {
    const errorMsg = response?.error?.response?.data?.error;
   dispatch( sendAlert({
    text: errorMsg,
    snack: true,
    snackTimeout: 4000,
    snackType: "error",
  }))
    return { status: 400 };
  }
else{
  console.log("outside the header")
  return response;

}
};