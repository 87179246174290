import moment from "moment";
import { IColumns } from "../../../types";

export const columns: IColumns[] = [
  {
    id: "timestamp",
    label: "Timestamp",
    render: (row) => moment(row.timestamp).format("HH:mm:ss MM/DD/YYYY"),
  },
  { id: "provider", label: "Provider" },
  { id: "integration", label: "Integration" },
  { id: "market", label: "Market" },
  { id: "storedId", label: "StoreId" },
  { id: "type", label: "Type" },
  { id: "updatedBy", label: "UpdatedBy" },
];

export const staticData = [
  {
    id: "1",
    timestamp: "2024-02-12T10:30:00Z",
    provider: "RBI",
    integration: "Simphony",
    market: "PLkh",
    storedId: "49342",
    type: "Store Config",
    updatedBy: "2024-02-12T10:30:00Z",
  },
  {
    id: "1",
    timestamp: "2024-02-12T10:30:00Z",
    provider: "RBI",
    integration: "Simphony",
    market: "PLkh",
    storedId: "49342",
    type: "Store Config",
    updatedBy: "2024-02-12T10:30:00Z",
  },
];

export const metadata = [
  {
    entityType: "KhumbuService",
    entity: ["ORDER", "ADJUST CART", "MENU", "STORE", "OTHER"],
  },
  {
    entityType: "API",
    entity: [
      "CREATE ORDER",
      "FIRE ORDER",
      "PROVIDER ORDER CANCEL ASYNC",
      "PROVIDER ORDER CANCEL SYNC",
      "PARTNER ORDER CANCEL",
      "ADJUST CART SYNC",
      "ADJUST CART ASYNC",
      "MENU SYNC",
      "PARTNER MENU UPDATE",
      "PARTNER MENU HOURS UPDATE",
      "PARTNER MENU ITEM UPDATE",
      "PARTNER MENU NOTIFICATION",
      "PROVIDER MENU UPDATE",
      "STORE SYNC",
      "STORE STATUS UPDATE",
      "OTHER",
    ],
  },
  {
    entityType: "Providers",
    entity: [
      "RBI",
      "Standard Provider 1",
      "Standard Provider 2",
      "Standard Provider3",
    ],
  },
  {
    entityType: "Partners",
    entity: ["IBR Integration", "NCR", "Simphony", "Simphonyv2", "simphonyv3"],
  },
  {
    entityType: "Markets",
    entity: ["bkat", "bkuae", "ibrmarket", "plkpl"],
  },
  {
    entityType: "Status",
    entity: ["SUCCESS", "FAILED"],
  },
];

export const auditLogDetails = {
  metricId: "679e600548cf63715ca725f7",
  timestamp: "2025-02-01T23:40:17",
  correlationId: "374fc5c6-9720-48b6-afc5-6cd3615bc87b",
  entityType: "STORE",
  entitySubType: "STORE SYNC",
  entityStatus: null,
  id: "1738432516372475142435",
  providerId: null,
  partnerId: null,
  providerStoreId: "810876",
  partnerStoreId: "3462",
  status: "SUCCESS",
  providerName: "RBI",
  partnerName: "NCR",
  brandName: "bkuae",
  errorMessage: null,
  errorCode: null,
  errorSource: null,
  errorType: null,
  nextSyncTime: null,
  internalErrorMessage: null,
  path: null,
};
