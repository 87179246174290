import { post } from "./common";
import config from "../config";
import { MyThunkAction } from "../types";
import { sendAlert } from "./UserAction";



export const ON_DASHBOARD_LOAD = "ON_DASHBOARD_LOAD";
export const ON_STATS_LOADING_START = "ON_LOADING_START";
export const ON_STATS_LOADING_FINISHED = "ON_LOADING_FINISHED";

export const getDashboardStats = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  dispatch({
    type: ON_STATS_LOADING_START,
    payload: true,
  });

  const response = await post(`/metrics/stats`,queryString)

  if(response.error){
      if (response?.error) {
        const errorMsg = response?.error?.response?.data?.error;
       dispatch( sendAlert({
        text: errorMsg,
        snack: true,
        snackTimeout: 4000,
        snackType: "error",
      }))
        return { status: 400 };
      }
  }
  else{
    dispatch({
      type: ON_DASHBOARD_LOAD,
      payload: response,
    });
  
    dispatch({
      type: ON_STATS_LOADING_FINISHED,
      payload: false,
    });
  
    return { status: 200 };
  }


};



